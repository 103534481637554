import axiosInstance from './manager/manager.service';

export const weekDayService = {
    
    get,
};


async function get() {

    try {
        let ret = await axiosInstance.get(`/weekdays`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

