<script>
import moment from "moment";
import { emissionService } from "../../../services/emission.service";
import { weekDayService } from "../../../services/weekday.service";
import { toastUtils } from "../../../utils/toast.utils";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

/**
 * Product Detail Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        DatePicker,
        Multiselect
    },
    data() {
        return {
            title: "Emission",
            items: [
                {
                    text: "Emission",
                    href: "/emissions"
                },
                {
                    text: "Détail",
                    active: true
                }
            ],
            emission: null,
            filesBaseUrl: process.env.VUE_APP_FILE_URL,
            isAdd: false,
            fichierEmission: null,
            showModal: false,
            dateFichier: null,
            deletePodcastId: null,
            deleteProgrammeId: null,
            weekDays: [],
            showModal2: false,
            programJour: null,
            horaires: [
                {
                    debut: null,
                    fin: null
                }
            ],
            debutHoraire: null,
            finHoraire: null,
            isLink: false,
            link: null,
            isSend: false

        };
    },
    methods: {
        /**
         * Selected image shows
         */
      
        async getEmission() {
            this.emission = await emissionService.getOne(this.$router.currentRoute.params.id);
        },
        async getWeekDay() {
            this.weekDays = await weekDayService.get();
        },
        formateDateForm(d) {
            return moment(d).format('YYYY-MM-DD');
        },
        formateTimeForm(d) {
            return moment(d).format('HH:mm:ss');
        },
        async ajouterFichier() {
            if (this.dateFichier == '' || this.dateFichier == null) {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'La date est obligatoire!');
                return;
            }
            if (this.isLink && (this.link == "" || this.link == null)) {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'Renseigner le lien du fichier!');
                return;
            }
            if (!this.isLink && this.fichierEmission == null) {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'Renseigner le fichier!');
                return;
            }
            let fichierForm = new FormData();

            fichierForm.append("date_emission", this.formateDateForm(this.dateFichier));
            if(this.isLink) fichierForm.append("link", this.link);
            if (!this.isLink) fichierForm.append("fichier", this.fichierEmission.file);
            this.isSend = true;
            let resp = await emissionService.addFile(this.emission.id, fichierForm);
            this.isSend = false;
            if (resp >= 400) {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                this.fichierEmission = null;
                this.dateFichier = null;
                await this.getEmission();
                this.showModal = false;
            }
        },
         back() {
            this.$router.back();
        },
        showDeleteModal(item) {
            this.deletePodcastId = item.id;
            this.$bvModal.show('delete-modal-center');

        },
        showDeleteProgramModal(item) {
            this.deleteProgrammeId = item.id;
            this.$bvModal.show('delete-modal-center-programme');

        },
        async deleteData() {

            let resp = await emissionService.delPodcast(this.deletePodcastId);

            if (resp >= 400) {
                toastUtils.error(this.$bvToast, 'Suppression', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                await this.getEmission();
            }
            this.deletePodcastId = null;
        },
        async deleteDataPrograme() {

            let resp = await emissionService.delProgramme(this.deleteProgrammeId);

            if (resp >= 400) {
                toastUtils.error(this.$bvToast, 'Suppression', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                await this.getEmission();
            }
            this.deleteProgrammeId = null;
        },
        deleteOnRow(index) {
           this.horaires.splice(index, 1);
        },
        async ajouterProgramme() {
            if (this.programJour == null) {
                toastUtils.error(this.$bvToast, 'Programmes', 'Selectionner un jour');
                return;
            }
            let check = true;
            this.horaires.forEach(e => {
                if (e.debut == null || e.fin == null) {
                    check = false;
                }
            })

            if (!check) {
                toastUtils.error(this.$bvToast, 'Programmes', 'Une date entrer est invalide');
                return;
            }

            
            let resp = null;
            this.horaires.forEach(async e => {
                let programmForm = new FormData();
                programmForm.append("weekDay", this.programJour.id);
                programmForm.append("debut", this.formateTimeForm(e.debut));
                programmForm.append("fin", this.formateTimeForm(e.fin));
                resp = await emissionService.addProgramme(this.emission.id, programmForm);
            })
            
            await this.getEmission();
            if (resp >= 400) {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                this.horaires = [{debut: null, fin: null}];
                this.showModal2 = false;
            }
        }
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.emissionData.length;
        },

    },
    mounted() {
        this.getEmission();
        this.getWeekDay();
    },
   
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            
            <div class="col-lg-12">
            <i @click="back()" style="font-size: 28px; cursor: pointer; margin-bottom: 10px;" class="fa fa-arrow-left" st></i>
                <div class="card">
                    <div v-if="emission != null" class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="row">
                                        
                                        <div class="col-md-10 col-9">
                                            <div class="tab-content" id="v-pills-tabContent">
                                                <div class="tab-pane fade show active" id="product-1" role="tabpanel">
                                                    <div class="product-img">
                                                        <img id="expandedImg" :src="`${filesBaseUrl}/emission/${emission.images[0]}`" alt
                                                            class="img-fluid mx-auto d-block" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->
                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4 mt-xl-3">
                                    <a href="#" class="text-primary">Emission</a>
                                    <h5 class="mt-1 mb-3">{{ emission.titre }}</h5>

                                   
                                    
                                    <p class="mt-3">{{ emission.description }}</p>
                                    <hr class="my-4" />

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div>

                                                <h5 class="font-size-14">Animateurs :</h5>
                                                <ul class="list-unstyled product-desc-list">
                                                    <li v-for="(animateur, index) of emission.animateurs" v-bind:key="index">
                                                        <i class="mdi mdi-circle-medium mr-1 align-middle"></i> {{ animateur.nom  }} {{ animateur.prenom }}
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </div>

                                        
                                    </div>

                                   
                                </div>
                            </div>
                        </div>
                        <!-- end row -->

                        <div class="mt-4">
                            <h5 class="font-size-14 mb-3">Informations supplémentaires</h5>
                            <div class="product-desc">
                                <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                                    <b-tab title="Programmes">
                                        <div style="text-align: right;">
                                             <a @click="showModal2 = true" href="javascript: void(0);" class="btn btn-primary waves-effect mt-4">
                                                            Ajouter un programme
                                                </a>
                                           </div><br>
                                        <div role="tablist">
                                            <b-card v-for="(days, index) of weekDays" v-bind:key="index" no-body class="mb-1 shadow-none">
                                                <b-card-header header-tag="header" role="tab">
                                                <h6 class="m-0">
                                                    <a
                                                    v-b-toggle.accordion-1
                                                    class="text-dark"
                                                    href="javascript: void(0);"
                                                    >{{ days.name }}</a>
                                                </h6>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    
                                                    <h5 class="font-size-14">Horaires :</h5>
                                                    <ul class="list-unstyled product-desc-list">
                                                        <li  v-for="(programmes, index) of emission.programmes" v-bind:key="index">
                                                            <div class="row col-md-12" v-if="programmes.weekDay.id == days.id">
                                                                <div class="col-md-6">
                                                                    <i  class="mdi mdi-circle-medium mr-1 align-middle"></i> 
                                                                    Début: {{ programmes.debut }} - Fin: {{ programmes.fin }}
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div @click="showDeleteProgramModal(programmes)" style="cursor: pointer;" class="avatar-sm mr-3">
                                                                        <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                                        <i class="mdi mdi-trash-can font-size-18" title="Supprimer"></i>
                                                                        </span>
                                                               
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                            </div>
                                    </b-tab>


                                    <b-tab title="Enregistrements">
                                       <div style="text-align: right;">
                                         <a @click="showModal = true; fichierEmission = null, dateFichier = null" href="javascript: void(0);" class="btn btn-primary waves-effect mt-4">
                                                        Ajouter un fichier
                                            </a>
                                       </div><br>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card">
                                                    <div class="card-body">
                                                        <div v-for="(fichiers, index) of emission.podcasts" v-bind:key="index" class="row">
                                                            <div title="Ecouter" style="cursor: pointer;" class="avatar-sm mr-3">
                                                               <a v-if="fichiers.fichier != null" :href="`${filesBaseUrl}/podcast/${fichiers.fichier}`" target="_blank" rel="noopener noreferrer">
                                                                 <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                                    <i class="ri-play-circle-line"></i>
                                                                    </span>
                                                               </a>
                                                               <a v-if="fichiers.link != null" :href="`${fichiers.link}`" target="_blank" rel="noopener noreferrer">
                                                                 <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                                    <i class="ri-play-circle-line"></i>
                                                                    </span>
                                                               </a>
                                                               
                                                            </div>
                                                            <div class="media-body align-self-center overflow-hidden">
                                                                <h5>Emission du {{ fichiers.date_emission | fileDateFormat }}</h5>
                                                                
                                                            </div>
                                                            <div class="media-body align-self-right overflow-hidden">
                                                               <div @click="showDeleteModal(fichiers)" style="cursor: pointer;" class="avatar-sm mr-3">
                                                                    <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                                    <i class="mdi mdi-trash-can font-size-18" title="Supprimer"></i>
                                                                    </span>
                                                               
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end card -->
                
            </div>
            <b-modal id="delete-modal-center" centered :title="'Supression'" title-class="font-18" @ok="deleteData">
                <p>
                    Confirmez-vous l'action?
                </p>
            </b-modal>
            <b-modal id="delete-modal-center-programme" centered :title="'Supression'" title-class="font-18" @ok="deleteDataPrograme">
                    <p>
                        Confirmez-vous l'action?
                    </p>
                </b-modal>
            <b-modal v-model="showModal" title="Nouveau Fichier" centered>
                <form>
                <div class=" mb-2">
                    <label for="">Date</label>
                    <date-picker
                        v-model="dateFichier"
                        placeholder="Selectionner la date"
                    ></date-picker>
                </div>
              
                <div >
                    <div class="form-group">
                        <label class="mt-3" for="validationCustom02">Est un lien <span style="font-size: 10; color: grey;">(Si vous voulez renseigner un lien)</span></label>
                         <b-form-checkbox
                            v-model="isLink"
                            name="check-button"
                            switch
                            >
                        </b-form-checkbox>
                    </div>
                </div>
                <div v-if="isLink">
                    <div class="form-group">
                        <label for="validationCustom01">Lien</label>
                        <input id="validationCustom01" v-model="link" type="text" class="form-control"
                            placeholder="Lien du fichier"/>
                    </div>
                </div>
                <div v-if="!isLink">
                    <label for="">Fichier</label>
                        <VueFileAgent @delete="fileDeleted($event)" v-model="fichierEmission" :multiple="false"
                            :deletable="true" :accept="'audio/mpeg, audio/ogg, audio/wav'"
                            :helpText="'Sélectionner le fichier'" 
                            :errorText="{
                                type: 'Type de fichier invalid'
                            }">
                        </VueFileAgent>
                </div>
                </form>
                <template v-slot:modal-footer>
                <b-button variant="secondary" @click="showModal = false; fichierEmission = null, dateFichier = null">Fermer</b-button>
              
                <b-button @click="ajouterFichier()" variant="primary">
                    Enregistrer
                    <b-spinner v-if="isSend" variant="light" small role="status"></b-spinner>
                </b-button>
                </template>
            </b-modal>

            <b-modal v-model="showModal2" title="Nouveau programme" centered>
                <form class="row">
                    <div class="col-md-12">
                    <div class="form-group">
                        <label class="pt-2" for="">Jour</label>
                        <multiselect :searchable="true" label="name" :select-label="''" :deselect-label="''"
                            trackBy="id" :placeholder="'Jours'" v-model="programJour" :options="weekDays" :multiple="false"></multiselect>
                    </div>
                </div>
                <div class="row col-md-12" v-for="(horaire, index) of horaires" v-bind:key="index">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label class="pt-2" for="">Début</label>
                            <date-picker
                                type="time"
                                v-model="horaire.debut"
                                placeholder="Heure début"
                            ></date-picker>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group">
                            <label class="pt-2" for="">Fin</label>
                            <date-picker
                                type="time"
                                v-model="horaire.fin"
                                placeholder="Heure fin"
                            ></date-picker>
                        </div>
                    </div>
                    <div v-if="horaires.length > 1" class="col-md-2 mt-4">
                        <div class="media-body align-self-right overflow-hidden">
                            <div @click="deleteOnRow(index)" style="cursor: pointer;" class="avatar-sm mr-3">
                                <span class="avatar-title bg-light rounded-circle text-primary font-size-14">
                                <i class="mdi mdi-trash-can font-size-18" title="Supprimer"></i>
                                </span>
                        
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <b-button class="btn-sm" @click="horaires.push({debut: null, fin: null})" variant="primary">
                        Ajouter
                    </b-button>
                </div>
                </form>
                <template v-slot:modal-footer>
                <b-button variant="secondary" @click="showModal2 = false, horaires = [{debut: null, fin: null}]; ">Fermer</b-button>
                <b-button @click="ajouterProgramme()" variant="primary">
                    Enregistrer
                </b-button>
                </template>
            </b-modal>
        </div>
    </Layout>
</template>